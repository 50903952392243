import { Box, Button, Flex, IconButton, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CTable from "../../../components/Table/table";
import Select from "react-select";
import {
  acceptStuentHomeworkRequest,
  deleteStuentHomeworkRequest,
  filterHomeworkAttendenceReportRequest,
  homeworkAttendenceReportRequest,
  homeworkUncommentReportRequest,
} from "../../../modules/admin-modules/reports/Actions";
import Layout from "../../../components/dashboard/Layout";
import LogoutMenu from "../../../components/dashboard/Layout/logout-menu";
import { CSVLink } from "react-csv";
import { getAllHomeworksRequest } from "../../../modules/admin-modules/homework/Actions";
import { RiDeleteBinLine } from "react-icons/ri";
import HomeworkComment from "../../../containers/admin/reports/homework-comment";
import HomeworkRefuseComment from "../../../containers/admin/reports/refuse-homework";
import { openAdminHomeworkCommentModel, openRefuseHomeworkModel } from "../../../modules/admin-modules/models/Actions";
import { getAllGroupsRequest } from "../../../modules/admin-modules/groups/Actions";
const HomeworkUncommentReport = () => {
  const dispatch = useDispatch();
  const [homeworkId, setHomeworkId] = useState("");
  const [groupId, setGroupId] = useState("all");

  const [offset, setPage] = useState(0);
  const [size, setPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [data, setData] = useState(null);
  useEffect(() => {
    if (homeworkId) dispatch(homeworkUncommentReportRequest(homeworkId));
  }, [homeworkId]);

  useEffect(() => {
    dispatch(getAllHomeworksRequest());
  }, []);

  const homeworks = useSelector((state) => state.homework.homeworks);
  const homeworkUncomment = useSelector(
    (state) => state.reports.homeworkUncomment
  );
  const isLoading = useSelector((state) => state.reports.isLoading);

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      background: "#f5f5f5",
      color: "#690000",
      borderRadius: "16px",
    }),
    option: (styles) => ({ ...styles, color: "#690000" }),
  };
  const homeworksOptions = homeworks?.map((data) => {
    return { value: data.id, label: data.name + " (" + data.unevaluated_count + ")" };
  });

  const viewData = (data) => {
    setData(data);
  };
  const headers = [{ label: "name", key: "name" }];
  const Data = homeworkUncomment?.map((data, index) => {
    return {
      ...data,
      index: `#${index + 1}`,
      id: data?.id,
      name: data?.student?.name,
      user_name: data?.student?.user_name,
      group: data?.student_group,
      preview_answer: (
        <Button onClick={() => dispatch(openAdminHomeworkCommentModel())}>
          مراجعة الحل
        </Button>
      ),
      delete: (
        <IconButton
          icon={<RiDeleteBinLine color="red" />}
          onClick={() =>
            dispatch(
              openRefuseHomeworkModel()
            )
          }
        />
      ),
    };
  });
  const columns = [
    {
      Header: "رقم الطالب",
      accessor: "index",
    },
    {
      Header: "اسم الطالب",
      accessor: "name",
    },
    {
      Header: "Code",
      accessor: "user_name",
    },
    {
      Header: "Student Group",
      accessor: "group",
    },
    {
      Header: " ",
      accessor: "preview_answer",
    },

    {
      Header: "  ",
      accessor: "delete",
    },
  ];
  const acceptAll = () => {
    dispatch(acceptStuentHomeworkRequest(homeworkId));
  };
  return (
    <Layout>
      <div style={{ minHeight: "400px" }}>
        <Flex
          w="100%"
          my="30px"
          justifyContent="space-between"
          alignItems="center"
          position="relative"
          zIndex="10"
          flexWrap="wrap"
        >
          <Text fontSize="24px" fontWeight="600" color="main_1">
            تقرير اضافة حل الواجبات
          </Text>
          <Flex
            w={{ base: "100%", md: "70%" }}
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <Box w="70%">
              <Select
                options={homeworksOptions}
                isMulti={false}
                onChange={(data) => {
                  setHomeworkId(data?.value);
                }}
                styles={colourStyles}
                placeholder="Select Homework"
              />
            </Box>

            <Box
              mx="10px"
              border="1px solid gray"
              borderRadius="8px"
              padding="10px"
            >
              <CSVLink
                headers={headers}
                data={Data?.length != 0 ? Data : []}
                filename={"homework-report.csv"}
                className="btn btn-primary"
                target="_blank"
              >
                Download
              </CSVLink>
            </Box>
            <LogoutMenu />
          </Flex>
        </Flex>

        <CTable
          selectedData={viewData}
          Columns={columns}
          Data={Data}
          path={"openModel"}
          Actions
          Title={``}
          subTitle=""
          btnTitle=""
          placeHolder=""
          noSearchBar={true}
          noFilter={true}
          footerBtnTitle
          filterList
          addButton
          addButtonTitle=""
          setPage={setPage}
          setPerPage={setPerPage}
          currentpage={pageNumber}
          setPageNumber={setPageNumber}
          noSecondSearchBar={true}
          isLoading={isLoading}
          perPage={size}
          totalPage={Math.ceil(10 / 50)}
        />
      </div>
      <HomeworkComment data={data} homeworkId={homeworkId} />
      <HomeworkRefuseComment data={data} homeworkId={homeworkId} />
    </Layout>
  );
};

export default HomeworkUncommentReport;
